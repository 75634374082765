<template>
  <div class="my-body-bg">
    <div class="head-box">
      <van-image
          radius="50%"
          width="60"
          height="60"
          :src="this.userInfo.headpic"

      />
      <div v-show="this.hasLogin" class="head-info">
        <h6>{{ this.userInfo.name }}</h6>
        <p>{{ this.userInfo.username.substring(0, 3) + '****' + this.userInfo.username.substring(7, 11) }}</p>
      </div>

      <van-button v-show="!(this.hasLogin)" @click="doLogin()" type="default">登录</van-button>
    </div>
    <div style="height: 20px;background: #f5f7f9;"></div>
    <van-cell is-link @click="telPopupShow = true">
      <template #title>
        <span class="iconfont icon-telephone" style="color:#50DB89;"></span>
        <span class="custom-title">联系客服</span>
      </template>
    </van-cell>
    <van-cell is-link to="/activate">
      <template #title>
        <span class="iconfont icon-_mima2" style="color:#FDDB46;"></span>
        <span class="custom-title">激活码</span>
      </template>
    </van-cell>
    <van-cell is-link  @click="goPostMessage">
      <template #title>
        <van-icon name="records" color="#1989fa" size="20" style="position: relative;top: 0.1rem;margin-right: 0.2rem"/>
        <span class="custom-title">发布信息</span>
      </template>
    </van-cell>
    <!-- 电话客服弹窗 -->
    <van-action-sheet v-model="telPopupShow" :closeable="false">
      <div class="serve-pop">
        <a class="serve-title" href="tel:028-8677029"><p>座机：028-86770219</p><span>点击拨打</span></a>
        <a class="btn-tel" href="tel:17381920029"><p>手机：17381920029</p><span>点击拨打</span></a>
        <div style="height: 10px;background: #f5f7f9;"></div>
        <div class="btn-cancel" @click="telPopupShow = false">取消</div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import api from '@/utils/api'
import router from "@/router";
import leaseApi from "../../utils/leaseApi";

export default {
  data() {
    return {
      telPopupShow: false,
      hasLogin: false,
      userInfo: {
        username: '--',
        name: '--',
        headpic: require('../../assets/img/my/icon_head.png'),
        id:''
      },
      registerParam: {}
    }
  },
  async created() {
    let _this = this;
    if (window.location.href.indexOf('hasLogin') !== -1) {
      //假登录
      // const tokenData = await leaseApi.falseLogin();
      // window.localStorage.setItem('token',tokenData.data.data.access_token)
      // const {data} = await api.getFalseUserInfo()
      // 生产环境登录
      const {data} = await api.getUserInfo();
      this.hasLogin = true
      this.userInfo = {
        username: data.username,
        name: data.name,
        headpic: data.headpic,
        id:data.id
      }
    } else if (window.location.href.indexOf('token') !== -1) {
      window.localStorage.setItem('token', this.GetRequest('token'))
      window.localStorage.setItem('phone', this.GetRequest('username'))
      const {data} = await api.getUserInfo();
      this.hasLogin = true
      this.userInfo = {
        username: data.username,
        name: data.name,
        headpic: data.headpic,
        id:data.id
      }
    } else if (window.location.href.indexOf('register') !== -1) {
      this.hasLogin = false
      this.registerParam = {
        openid: _this.GetRequest("openid"),
        headpic: decodeURIComponent(_this.GetRequest("pic")),
        diqu: decodeURI(_this.GetRequest('diqu')),
        name: decodeURI(_this.GetRequest('name')),
        unionid: _this.GetRequest('unionid')
      }
    }
  },
  methods: {
    GetRequest(name) {
      var url = window.location.href; //获取url中"?"符后的字串
      // var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        let str = url.substr(1);
        if (str.indexOf("#" != -1)) {
          str = str.substr(0);
        }
        let strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          if (strs[i].indexOf(name) != -1) {
            return strs[i].split("=")[1];
          }
        }
      }
      return null;
    },
    doLogin() {
      let _this = this
      this.$router.push({
        path: '/login', query: {
          openid: _this.registerParam.openid,
          diqu: _this.registerParam.diqu,
          headpic: _this.registerParam.headpic,
          name: _this.registerParam.name,
          unionid: _this.registerParam.unionid
        }
      })
    },
    goPostMessage(){
      this.$router.push({name:'postMessage',params:{id:this.userInfo.id}})
    }
  }
}
</script>
<style lang="less" scoped>

.my-body-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: auto;
  background-color: #fff;
}

.head-box {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 60px 30px;

  .van-image {
    margin-right: 30px;
  }

  .van-button {
    height: 60px;
    padding: 0 40px;
    color: #867878;
  }

  .head-info {
    -webkit-flex: 1;
    flex: 1;

    h6 {
      font-size: 36px;
      font-weight: 400;
      color: #333;
      margin-bottom: 10px;
    }

    p {
      font-size: 28px;
      font-weight: 400;
      color: #666;

    }
  }
}

.iconfont {
  font-size: 36px;
  vertical-align: middle;
  margin-right: 20px;
}

// 电话客服弹窗
/* 弹窗 */
.serve-pop {
  a {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;

    p {
      -webkit-flex: 1;
      flex: 1;
    }

    span {
      background: rgb(254, 138, 79);
      padding: 5px 10px;
      border-radius: 5px;
      color: #fff;
    }
  }

  .serve-title {
    font-size: 28px;
    padding: 42px 30px 36px;
    color: #666;
  }

  .btn-tel {
    color: #666;
    font-size: 28px;
    padding: 36px 30px;
    border-top: 1px solid #E1E1E1;
  }

  .btn-cancel {
    color: #333333;
    font-size: 28px;
    text-align: center;
    padding: 36px 0;
    display: block;
  }

}
</style>
